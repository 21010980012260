import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
// import Link from '@mui/material/Link';
// import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';

import MenuItem from '@mui/material/MenuItem';

import { useNavigate, useLocation, useEffect } from 'react-router-dom';

const pages = [
  {
    protected: true,
    name: 'Products', 
    href: '/products'
  },
  // {
  //   protected: false,
  //   name: 'ESH Pricing', 
  //   href: '/pricing/esh'
  // },
  {
    protected: false,
    name: 'ECS Pricing', 
    href: '/pricing/ecs'
  }
];

const settingsLoggedIn = [
  {
    name: 'Logout',
    href: '/logout'
  }
];

export function Header({authenticatedUser}) {
  const navigate = useNavigate()

  const [anchorElNav, setAnchorElNav] = React.useState();
  const [anchorElUser, setAnchorElUser] = React.useState();
  const [headerUser, setHeaderUser] = React.useState(false);

  React.useEffect(()=>{
    if (authenticatedUser) {
      setHeaderUser(authenticatedUser)
    }
  }, [authenticatedUser])

  setTimeout(() => {
    setHeaderUser(authenticatedUser)

}, 2000)
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = (to) => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const Logo=require("../cto-logo-white-transparent.png");

  let urlLocation = useLocation();

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
        <Box
            component="img"
            sx={{
              flexGrow: 1, 
              display: 
              { xs: 'none', md: 'flex' },
              // height: 54,
              maxWidth: '400px',
              marginRight: '30px'
            }}
            alt="Logo."
            src={Logo}
        />

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <MenuItem 
                key={page.name} 
                onClick={handleCloseNavMenu}
                sx={{
                  display: headerUser || page.protected === false ? 'block' : 'none',
                  backgroundColor: urlLocation.pathname.startsWith(page.href) ? 'primary.light' : ''
                }}
                >
                  {headerUser || page.protected === false ? 
                  <Typography 
                    component="a" 
                    href={page.href} 
                    textAlign="center" 
                    sx={{
                      color: urlLocation.pathname.startsWith(page.href) ? 'white' : 'black',
                      backgroundColor: urlLocation.pathname.startsWith(page.href) ? 'primary.light' : '', 
                      textDecoration: 'none'
                    }}>
                      {page.name}
                  </Typography>  : ''}
                </MenuItem>
              ))}
              { ! headerUser ? 
              <MenuItem 
                key="signin"
                sx={{
                  backgroundColor: urlLocation.pathname === '/' ? 'primary.light' : ''
                }}
              >
                <Typography
                sx={{
                  color: urlLocation.pathname === '/' ? 'white' : 'black',
                  backgroundColor: urlLocation.pathname === '/' ? 'primary.light' : '', 
                  textDecoration: 'none'
                }} component="a" href="/logout" textAlign="center">Sign In</Typography>
              </MenuItem> : '' }
            </Menu>
          </Box>
          <Typography
            component="img"
            src={Logo}
            sx={{
              mr: 2,
              maxWidth: '250px',
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
            }}
          >
            
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page) => (
              <Button
                component="a" href={page.href}
                key={page.name}
                onClick={handleCloseNavMenu}
                sx={{ 
                  my: 2, 
                  color: 'white', 
                  backgroundColor: urlLocation.pathname.startsWith(page.href) ? 'primary.light' : '#00947C', 
                  display: headerUser || page.protected === false ? 'block' : 'none',
                  "&:hover": {
                    backgroundColor: "primary.light",
                    cursor: 'pointer',
                    // color: 'white!important'
                  }
                }}
              >
                {page.name}
              </Button>
            ))}
              <Button
                component="a" href="/logout"
                key="signin"
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: 'white', marginLeft: "auto", marginRight: 0, display: headerUser ? 'none' : 'block'} }
              >
                Sign In
              </Button>
          </Box>

          {headerUser ? <Box sx={{ flexGrow: 0 }}>
            <Tooltip title={headerUser}>
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0, m:0 }}>
                <Avatar alt={headerUser} src="/static/images/avatar/2.jpg" />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settingsLoggedIn.map((setting) => (
                <MenuItem key={setting.name} component="a" href={setting.href}>
                  {setting.name}
                </MenuItem>
              ))}
            </Menu>
          </Box> : '' }
          
        </Toolbar>
      </Container>
    </AppBar>
  );
}
// export default Header;