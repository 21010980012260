import _ from 'lodash';
import axios from 'axios';
import React from 'react';
import {useEffect} from 'react';
import { useParams } from "react-router-dom";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { useForm } from "react-hook-form";

import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';


import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import LinearProgress from '@mui/material/LinearProgress';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';



import { useNavigate, Navigate } from "react-router-dom";

import {checkApiError} from '../common/Utils';

import moment from "moment";

import { ProductEcs } from './ProductEcsRepoForm'

function CustomTabPanel(props) {

  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ paddingTop: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


export function Product({authenticatedUser}) {
  const [loading, setLoading] = React.useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const [cloudUser, setCloudUser] = React.useState(false);
  const [metrics, setMetrics] = React.useState(null);
  const [product, setProduct] = React.useState({});
  const [value, setValue] = React.useState(0);
  const [errorMessage, setErrorMessage] = React.useState(null);

  const navigate = useNavigate()
  // if (!authenticatedUser) {
  //   navigate('/logout')
  // }

  let { productCode } = useParams();

  const getEcsUser = async () => {
    setLoading(true)
    const accessToken = 'Bearer ' + localStorage.getItem('accessToken')
    const apiUrl = process.env.REACT_APP_CTO_API + '/ecs/user'
    setLoading(true)
    await axios.get(apiUrl, { headers: { Authorization: accessToken } })
      .then((res) =>  {
        setCloudUser(true)
        if (product) setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
        checkApiError(error, navigate, setErrorMessage)
        if (error.response?.status == 404) {
          setCloudUser(false)
          setLoading(false)
        }
      })
  }

  const getProducts = async () => {
    setLoading(true)
    const accessToken = 'Bearer ' + localStorage.getItem('accessToken')
    const apiUrl = process.env.REACT_APP_CTO_API + '/products'

    await axios.get(apiUrl, { headers: { Authorization: accessToken } })
      .then((res) =>  {
        setProduct(res.data.filter((e) => e.product_name === productCode)[0])
        if (cloudUser) setLoading(false)
      })
      .catch((error) => {
        checkApiError(error, navigate, setErrorMessage)
        let data = [{product_name: 'There was an error loading your products'}]
        setLoading(false)
      })
  }

  const getMetrics = async () => {
    const accessToken = 'Bearer ' + localStorage.getItem('accessToken')
    const apiUrl = process.env.REACT_APP_CTO_API + '/ecs/metrics'

    await axios.get(apiUrl, { headers: { Authorization: accessToken } })
      .then((res) =>  {
        setMetrics(res.data)
        // setProduct(res.data.filter((e) => e.product_name === productCode)[0])
      })
      .catch((error) => {
        setMetrics('No usage data has been sent. Check back later')
        // checkError(error)
        let data = [{product_name: 'There was an error loading your products'}]
      })
  }

  useEffect(() => {
    if (productCode === 'ecs') getEcsUser()
    getProducts()
    getMetrics()
  }, [])
  
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleProductClick = product => {
    const settings_page  = product.product_name === 'ecs' ? 'settings' : 'settings'
    navigate('/products/' + product.product_name + '/' + settings_page)
  }


  const onSubmit = (data) => {
  };

  const recommendedSubdomain = 'cto'

  return (
      <Container maxWidth="md">
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center">
          <Breadcrumbs 
            aria-label="breadcrumb"
            sx={{marginTop: '20px'}}>
            <Link underline="hover" color="inherit" href="/products">
              Products
            </Link>
            <Typography color="text.primary">{_.upperCase(productCode)}</Typography>
          </Breadcrumbs>
          { errorMessage && 
            <Typography variant="body1" gutterBottom
              sx={{mt: 6, mb: 6, color: 'red'}}
              >
              {errorMessage}
            </Typography>
          }
        </Grid>

        <Grid
          container
          spacing={0}
          direction="column"
          alignItems=""
          justifyContent="center">
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} aria-label="Tabs">
              <Tab label="Details" {...a11yProps(0)} />
              <Tab label="Usage" {...a11yProps(1)} />
            </Tabs>
          </Box>

      
          <CustomTabPanel value={value} index={0}>
            <TableContainer component={Paper}>
              <Table 
                selectable="true"
                // sx={{ 
                //   // minWidth: 650,
                //   "& .MuiTableRow-root:hover": {
                //     // backgroundColor: "primary.light",
                //     cursor: 'pointer',
                //     // color: 'white!important'
                //   }
                // }} 
                size="small" 
                aria-label="Product Table">

                          
                <TableBody>
                  {loading ? <TableRow key="loading">
                    <TableCell colSpan={2}>
                      <LinearProgress color="secondary" style={{display: true ? 'block' : 'none'}}/>
                    </TableCell>
                  </TableRow> : ''}
                  {!loading && <Box>
                    <TableRow>
                      <TableCell sx={{paddingTop: 3, fontWeight: 'bold'}}>Product Name:</TableCell>
                      <TableCell sx={{paddingTop: 3}}>{product?.license?.name} </TableCell>
                    </TableRow>
                    {product?.license?.onprem && <TableRow>
                      <TableCell sx={{
                        paddingTop: 3, 
                        fontWeight: 'bold', 
                        }}>On-prem API Key:</TableCell>
                      {product?.license?.onprem && <TableCell sx={{
                          paddingTop: 3,
                          overflowWrap: 'anywhere',
                          maxWidth: '100%',
                          overflow: 'hidden',
                          // whiteSpace: 'nowrap'
                        }}>
                          {product?.api_key} 
                      </TableCell> }
                    </TableRow> }                  
                    <TableRow>
                      <TableCell sx={{paddingTop: 3, fontWeight: 'bold'}}>License Expires:</TableCell>
                      <TableCell sx={{paddingTop: 3}}>{moment(product?.license_expires).format('MM-DD-YYYY')}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{paddingTop: 3, fontWeight: 'bold'}}>On-premise License:</TableCell>
                      <TableCell sx={{paddingTop: 3}}>{product.license?.onprem === false ? 'No' : 'Yes'}</TableCell>
                    </TableRow>
                    {product?.product_name === 'ecs' && <TableRow>
                      <TableCell sx={{paddingTop: 3, fontWeight: 'bold'}}>Licensed Repositories:</TableCell>
                      <TableCell sx={{paddingTop: 3}}>{product?.license?.repos}</TableCell>
                    </TableRow> }
                    {product?.product_name === 'ecs' && <TableRow>
                      <TableCell sx={{paddingTop: 3, fontWeight: 'bold'}}>Licensed Users:</TableCell>
                      <TableCell sx={{paddingTop: 3}}>{product?.license?.users}</TableCell>
                    </TableRow> }
                    {product?.product_name === 'ecs' && <TableRow>
                      <TableCell sx={{paddingTop: 3, fontWeight: 'bold'}}>Licensed API Calls:</TableCell>
                      <TableCell sx={{paddingTop: 3}}>{product?.license?.api_calls}</TableCell>
                    </TableRow> }
                    {product?.product_name === 'esh' && <TableRow>
                      <TableCell sx={{paddingTop: 3, fontWeight: 'bold'}}>Licensed Users:</TableCell>
                      <TableCell sx={{paddingTop: 3}}>{product?.license?.users === 0 ? 'Unlimited' : product?.license?.users}</TableCell>
                    </TableRow> }
                    {product?.product_name === 'esh' && <TableRow>
                      <TableCell sx={{paddingTop: 3, fontWeight: 'bold'}}>Licensed Tenants:</TableCell>
                      <TableCell sx={{paddingTop: 3}}>{product?.license?.tenants === 0 ? 'Unlimited' : product?.license?.tenants}</TableCell>
                    </TableRow> }
                    <TableRow>
                      <TableCell sx={{paddingTop: 3, fontWeight: 'bold'}}>License Cost:</TableCell>
                      <TableCell sx={{paddingTop: 3}}>${product?.license?.price}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{paddingTop: 3, fontWeight: 'bold'}}>Cloud:</TableCell>
                      <TableCell sx={{paddingTop: 3}}>
                        { product &&
                        <Button
                          onClick={() => {
                            handleProductClick(product)
                          }}
                          // sx={{width: "310px"}} 
                          variant="outlined">
                          {cloudUser || product?.details?.subdomain ? 'Settings' : product.product_name === 'esh' ? 'Create ESH Cloud Organization' : 'Create ECS Cloud Organization'}
                        </Button>
                        }
                      </TableCell>
                    </TableRow>
                  </Box> }
                </TableBody>
              </Table>
            </TableContainer>   
            {!loading && product?.product_name === 'ecs' && 
            <Stack sx={{ mt:2, width: '100%' }} spacing={2}>
              {product.license?.onprem && <Alert severity="info">
                To use your API key and get started with ECS On-Premise, click <a target="_new" href="https://doc.cloudtechnologyoffice.com/ecs/latest/installation/server/">here</a>.
              </Alert>}
              <Alert severity="info">
                To use ECS Cloud, click Create ECS Cloud Organization and follow the documentation link provided on that page.
              </Alert>
            </Stack>
            }
            {!loading && product?.product_name === 'esh' && <Stack sx={{ mt:2, width: '100%' }} spacing={2}>
              <Alert severity="info">
                To use your API key and get started with ESH On-Premise, click <a target="_new" href="https://doc.cloudtechnologyoffice.com/esh/installation/own-cloud/">here</a>.
                <p>Contact us and we'll share the ESH Service Catalog product with you. </p>
              </Alert>
            </Stack>
            }

           
          </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          { productCode === 'esh' && 
          <Box>
            <Typography variant="body1" gutterBottom
              sx={{mb: 3, mt: 3}}
              >
                Usage statistics for ESH are coming soon! 
            </Typography>
          </Box>
          }
          { productCode === 'ecs' && 
          <Box>
            <Typography variant="body1" gutterBottom
              sx={{mb: 3, mt: 3}}
              >
                Usage in current license period is shown below. Your usage will  reset each period. 
            </Typography>

            <TableContainer component={Paper}>
              <Table 
                  selectable="true"
                  // sx={{ 
                  //   // minWidth: 650,
                  //   "& .MuiTableRow-root:hover": {
                  //     // backgroundColor: "primary.light",
                  //     cursor: 'pointer',
                  //     // color: 'white!important'
                  //   }
                  // }} 
                  size="small" 
                  aria-label="Usage Table">

                            
                  <TableBody>
                    {loading ? <TableRow key="loading">
                      <TableCell colSpan={2}>
                        <LinearProgress color="secondary" style={{display: true ? 'block' : 'none'}}/>
                      </TableCell>
                    </TableRow> : ''}

                    {!loading && <TableRow>
                      <TableCell component="th" sx={{paddingTop: 3, fontWeight: 'bold'}}>Metric</TableCell>
                      <TableCell component="th"  sx={{paddingTop: 3, fontWeight: 'bold'}}>Usage in Period</TableCell>
                    </TableRow> }
                    {!loading && <TableRow>
                      <TableCell sx={{paddingTop: 3}}>API Calls</TableCell>
                      <TableCell sx={{paddingTop: 3}}>{metrics?.api_calls} </TableCell>
                    </TableRow> }
                    {!loading && <TableRow>
                      <TableCell sx={{paddingTop: 3}}>Users:</TableCell>
                      <TableCell sx={{paddingTop: 3}}>{metrics?.users} </TableCell>
                    </TableRow> }
                  </TableBody>
                </Table>
              </TableContainer> 
          </Box>
          }
 
        </CustomTabPanel>

       </Grid>
      </Container>
  );
}