import _, { update } from 'lodash';
import axios from 'axios';
import React from 'react';
import {useEffect, useState} from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { useForm } from "react-hook-form";
import Typography from '@mui/material/Typography';
import {Select, MenuItem, styled} from '@mui/material'
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import InputAdornment from '@mui/material/InputAdornment';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import Switch from '@mui/material/Switch';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import LinearProgress from '@mui/material/LinearProgress';

import { useNavigate, Navigate } from "react-router-dom";
import {checkApiError} from '../common/Utils';
import { CtoButton } from '../common/Buttons'

import Confetti from '../common/Confetti';
import Fireworks from "https://esm.sh/react-canvas-confetti/dist/presets/fireworks";

export function ProductEss({authenticatedUser}) {
  const navigate = useNavigate()
  
  if (!authenticatedUser) {
   navigate('/logout')
  }

  
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const [loading, setLoading] = React.useState(false);
  const [formSubmitting, setFormSubmitting] = React.useState(false);
  const [formSubmitStatus, setFormSubmitStatus] = useState(false);
  const [formError, setFormError] = useState(null);
  const [recommendedSubdomain, setRecommendedSubdomain] = useState(null);
  const [showConfetti, setShowConfetti] = useState(false)
  const [product, setProduct] = useState({})
  
  const [settings, setSettings] = React.useState();  
  
  const checkAuthenticated = function(error) {
    if (error?.response?.status === 403) return navigate('/logout')
  }

  let productOption

  const getProducts = async () => {
    setLoading(true)
    const accessToken = 'Bearer ' + localStorage.getItem('accessToken')
    const apiUrl = process.env.REACT_APP_CTO_API + '/products'

    await axios.get(apiUrl, { headers: { Authorization: accessToken } })
      .then((res) =>  {
        const product = res.data.filter((e) => e.product_name === 'esh')[0]
        setProduct(product)
        const subdomain = product?.details?.subdomain ? product?.details?.subdomain : authenticatedUser.split('@')[1].split('.')[0]
        setRecommendedSubdomain(subdomain)
        setValue('subdomain', subdomain)
  
        setLoading(false)
      })
      .catch((error) => {
        checkApiError(error, navigate, setFormError)
        let data = [{product_name: 'There was an error loading your products'}]
        setLoading(false)
      })
  }

  const createOrganization = async (form) => {
    setFormSubmitting(true)

    const payload = {
        subdomain: form.subdomain
    }
    const accessToken = 'Bearer ' + localStorage.getItem('accessToken')

    axios.post(process.env.REACT_APP_CTO_API + '/esh/create_org', payload, { headers: { Authorization: accessToken }})
    .then(() =>  {
      setShowConfetti(true)
      setTimeout(() => {
        setShowConfetti(false)
      }, 2000)
      setFormSubmitting(false)
      setFormError(null)
      setFormSubmitStatus(true)
    })
    .catch((error) => {
        setFormSubmitting(false)
        setFormSubmitStatus(false)
        setFormError(error.response?.data.detail ? error.response.data.detail: 'Unknown error has occured. Please email support@cloudtechnologyoffice.com')
    })
  }

  const onSubmit = (data) => {
    createOrganization(data)
  };


  useEffect(()=>{
    if (authenticatedUser) {
      getProducts()
  }
  }, [authenticatedUser])


  return (
    <Container sx={{width:"900px"}}  maxWidth={false}>
      <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          >
          <Breadcrumbs 
            aria-label="breadcrumb"
            sx={{marginTop: '20px'}}>
            <Link underline="hover" color="inherit" href="/products">
              Products
            </Link>
            <Link underline="hover" color="inherit" href="/products/esh">
              ESH
            </Link>
            <Typography color="text.primary">Cloud Settings</Typography>
          </Breadcrumbs>


          { formSubmitStatus === true && 
            <Typography variant="body1" gutterBottom
            sx={{m: 3}}
            >
              Your Enterprise Service Hub Organization is being created. Watch out for an email from support@cloudtechnologyoffice.com. The email has your username, temporary password and login link to your ESH domain.
          </Typography>
          }
          { showConfetti && <Confetti></Confetti> }

        { formSubmitStatus === false && 
        <form
          autoComplete="off" 
          onSubmit={handleSubmit(onSubmit)} 
          noValidate
          className="form">
          { ! product?.details?.created && 
            <Box>
              <Typography variant="body1" gutterBottom
                sx={{m: 3}}
                >
                  Create Enterprise Service Hub Organization
              </Typography>
              <Typography variant="body1" gutterBottom
                sx={{m: 3}}
                >
                  Choose a subdomain and then click Create Organization. The creation takes approximately 5 minutes. You will receive an email with your login details. 
              </Typography>
            </Box>
          }
          { product?.details?.created && 
            <Box>
              <Typography variant="body1" gutterBottom
                sx={{m: 3}}
                >
                  You can't change your ESH domain but if you made a mistake, contact us at support@cloudtechnologyoffice.com and we'll change it for you.
              </Typography>
            </Box>
          }

          <FormControl onChange={(event) => setRecommendedSubdomain(event.target.value)} variant="standard" sx={{ m: 1, minWidth: 500, marginTop: '20px', width: '100%' }}>
            <TextField
              error = {errors.subdomain !== undefined}
              value={recommendedSubdomain ? recommendedSubdomain : ''}
              disabled={product?.details?.created ? true : false}
              label="Choose Subdomain"
              sx={{ m: 0, marginTop: '20px', width: '100%' }}
              helperText={errors.subdomain ? "Subdomain is required, must be alphanumeric and must be at least 3 characters" : ""}
              {...register("subdomain", { required: true, minLength: 3, maxLength: 50, pattern: /^[a-z0-9]+$/i})}
              InputProps={{
                endAdornment: <InputAdornment position="end">.enterpriseservicehub.com</InputAdornment>,
              }}
            />        
          </FormControl>
        
          <CtoButton 
            endIcon={<ArrowForwardIcon/>} 
            variant="contained" 
            type="submit"
            onClick={handleSubmit}
            sx={{marginBottom: '20px'}}
            disabled={formSubmitting || product?.details?.created}>
            { product?.details?.created ? 'Save' : 'Create Organization'}
          </CtoButton>


          <LinearProgress color="secondary" style={{display: formSubmitting || loading ? 'block' : 'none'}}/>
        </form> }

      </Grid>
    </Container>
  );
}