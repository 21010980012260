import { useState, useEffect } from 'react';

import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';

import PageNotFound from './pages/PageNotFound';
import { Signup } from './pages/Signup';
import { Payments } from './pages/Payments';
import { Payment } from './pages/Payment';
import { Product } from './pages/Product';
import { Products } from './pages/Products';
import { ProductEcsRepos } from './pages/ProductEcsRepos';
import { ProductEcsRepoForm } from './pages/ProductEcsRepoForm';
import { ProductEss } from './pages/ProductEss';
import { Pricing } from './pages/Pricing';
import { Authenticate } from './pages/Authenticate';
import { Header } from './pages/Header';

import { fetchAuthSession } from "aws-amplify/auth";
import { Hub } from 'aws-amplify/utils';
import { signOut } from 'aws-amplify/auth';

export const AppRoutes = () => {
    const navigate = useNavigate()
    const [authenticatedUser, setAuthenticatedUser] = useState(false);

    const getSession = async () => {
      const {
        tokens,
        credentials,
        identityId,
        userSub
      } = await fetchAuthSession();

      if (tokens?.signInDetails) {
          setAuthenticatedUser(tokens.signInDetails?.loginId)
          const {
              idToken,
              accessToken
          } = tokens;

          localStorage.setItem('accessToken', accessToken.toString())
          
          if (window.location.href.split('/')[3] === '') navigate('/products')
        
      } else {
          localStorage.removeItem('accessToken')
          setAuthenticatedUser(null)
      }
    }

    const listener = (data) => {
      getSession()
    };

    useEffect(() => {
      getSession();
    }, []);

    return (
      <Box>
        <Header authenticatedUser={authenticatedUser}></Header>
        <Routes>
          <Route exact path="/" element={<Authenticate/>}/>
          <Route exact path="/:signout" element={<Authenticate/>}/>
          <Route exact path="/products" element={<Products authenticatedUser={authenticatedUser}/>}/>
          <Route exact path="/products/:productCode" element={<Product authenticatedUser={authenticatedUser}/>}/>
          <Route exact path="/products/ecs/settings" element={<ProductEcsRepos authenticatedUser={authenticatedUser}/>}/>
          <Route exact path="/products/ecs/settings/:repoName" element={<ProductEcsRepoForm authenticatedUser={authenticatedUser}/>}/>
          <Route exact path="/products/esh/settings" element={<ProductEss authenticatedUser={authenticatedUser}/>}/>
          <Route exact path="/pricing/:product_code" element={<Pricing/>}/>
          <Route exact path="/signup/:productCode" element={<Signup authenticatedUser={authenticatedUser}/>}/>
          <Route exact path="/signup/:productCode/:productOption" element={<Signup authenticatedUser={authenticatedUser}/>}/>
          <Route exact path="/payments/:status/:product_code" element={<Payments authenticatedUser={authenticatedUser}/>}/>
          <Route exact path="/payment/:status/:product_code" element={<Payment authenticatedUser={authenticatedUser}/>}/>
              
          <Route path="*" element={<PageNotFound/>}/>
        </Routes>
      </Box>
    )
}