import _, { update } from 'lodash';
import axios from 'axios';
import React from 'react';
import { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { useForm } from "react-hook-form";
import Typography from '@mui/material/Typography';
import { Select, MenuItem, styled } from '@mui/material'
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import Switch from '@mui/material/Switch';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

import { useNavigate, Navigate } from "react-router-dom";
import { checkApiError } from '../common/Utils';
import { CtoButton } from '../common/Buttons'

import LinearProgress from '@mui/material/LinearProgress';

export function ProductEcsRepoForm({ authenticatedUser }) {
  const navigate = useNavigate()
  let { repoName } = useParams();
  // if (!authenticatedUser) {
  //   window.location.href='/logout'
  // }


  const {
    register,
    handleSubmit,
    setValue,
    formState: { isDirty, errors }
  } = useForm();

  const [loading, setLoading] = React.useState(false);
  const [formSubmitting, setFormSubmitting] = React.useState(false);
  const [formSubmitStatus, setFormSubmitStatus] = useState(false);
  const [formError, setFormError] = useState(null);
  const [deleteConfirmed, setDeleteConfirmed] = useState(null);


  const [sopsEncryption, setSopsEncryption] = React.useState({});
  const [awsAccessKeyId, setAwsAccessKeyId] = React.useState(null);
  const [azureTenantId, setAzureTenantId] = React.useState(null);
  const [azureClientId, setAzureClientId] = React.useState(null);
  const [azureClientSecret, setAzureClientSecret] = React.useState(null);
  const [awsRoleArn, setAwsRoleArn] = React.useState(null);
  const [awsRoleArnFromSettings, setAwsRoleArnFromSettings] = React.useState(null);


  const [repoSettings, setRepoSettings] = React.useState();
  const [settings, setSettings] = React.useState();
  const [editMode, setEditMode] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [updateSecrets, setUpdateSecrets] = React.useState(true);
  const [updateGitToken, setUpdateGitToken] = React.useState(true);

  const [gitProvider, setGitProvider] = React.useState('github');
  const [gitUrl, setGitUrl] = React.useState(null);
  // const [repoName, setRepoName] = React.useState('default');  
  const [cloudProvider, setCloudProvider] = React.useState('aws');
  const [awsKeyProvider, setAwsKeyProvider] = React.useState('aws_customer_key');
  const [awsCredentialsType, setAwsCredentialsType] = React.useState('aws_role');

  const [repos, setRepos] = React.useState([]);
  const [repoFormName, setFormRepoName] = React.useState(null);
  const [awsRoleCreated, setAwsRoleCreated] = React.useState(false)
  const [ecsExecutorRoleArn, setEcsExecutorRoleArn] = React.useState(null)

  const getUserInfo = async () => {
    setLoading(true)
    const accessToken = 'Bearer ' + localStorage.getItem('accessToken')
    const apiUrl = process.env.REACT_APP_CTO_API + '/ecs/info'
    await axios.get(apiUrl, { headers: { Authorization: accessToken } })
      .then((res) => {
        setLoading(false)
        setAwsRoleCreated(res.data.created)
        setEcsExecutorRoleArn(res.data.ecs_aws_role_arn)
      })
      .catch((error) => {
        console.log('info', error)
        checkApiError(error, navigate, setFormError)
        setLoading(false)
      })
  }

  const getSettings = async () => {
    console.log('get settings')
    setLoading(true)
    const accessToken = 'Bearer ' + localStorage.getItem('accessToken')
    const apiUrl = process.env.REACT_APP_CTO_API + '/ecs/user'

    setFormRepoName(repoName === 'new' ? '' : repoName)
    await axios.get(apiUrl, { headers: { Authorization: accessToken } })
      .then((res) => {
        setLoading(false)
        setFormError(null)
        setSettings(res.data)
        if (!_.isEmpty(res.data.repos_config)) {
          const repoSettings = res.data.repos_config[repoName]
          if (repoSettings) { // this won't be set if creating a second.... repo
            setRepoSettings(repoSettings)
            setUpdateGitToken(false)
            setUpdateSecrets(false)
            setGitProvider(repoSettings.git_provider)
            setGitUrl(repoSettings.git_url)
            const cloud_provider = repoSettings.cloud_option.split('_')[0]
            setCloudProvider(cloud_provider)
            setSopsEncryption(repoSettings.sops_encryption)

            if (cloud_provider === 'aws') {
              setAwsKeyProvider(repoSettings.sops_encryption ? 'aws_customer_key' : 'aws_cto_key')
              setAwsCredentialsType(repoSettings.cloud_option)
              setAwsAccessKeyId(repoSettings.cloud_env?.AWS_ACCESS_KEY_ID)
              setAwsRoleArn(repoSettings.cloud_env?.AWS_ROLE_ARN)
              setAwsRoleArnFromSettings(repoSettings.cloud_env?.AWS_ROLE_ARN)
            }

            if (cloud_provider === 'azure') {
              setAzureTenantId(repoSettings.cloud_env.AZURE_TENANT_ID)
              setAzureClientId(repoSettings.cloud_env.AZURE_CLIENT_ID)
            }
          }
        }
      })
      .catch((error) => {
        console.log('user', error)
        checkApiError(error, navigate, setFormError)
        if (error.response?.status === 404) {
          setUpdateGitToken(true)
          setUpdateSecrets(true)
        }
        setLoading(false)
      })
  }

  useEffect(() => {
    getSettings()
    getUserInfo()
  }, [])

  const getCreatePayload = function (form) {
    let payload = {}
    payload[form.repo_name] = {
      git_provider: form.git_provider,
      git_url: form.git_url,
      cloud_env: {}
    }
    let repo_config = payload[form.repo_name]

    if (form.git_token) {
      repo_config.git_token = form.git_token
    }

    switch (cloudProvider) {
      case 'aws':
        switch (awsKeyProvider) {
          case 'aws_cto_key':
            repo_config.cloud_option = 'aws_ecs_key'
            repo_config.cloud_env = null
            break;
          case 'aws_customer_key':
            repo_config.sops_encryption = form.aws_key_arn
            switch (awsCredentialsType) {
              case 'aws_role':
                repo_config.cloud_option = 'aws_role'
                repo_config.cloud_env.aws_role_arn = 'placeholder'
                break;
              case 'aws_creds':
                repo_config.cloud_option = 'aws_creds'
                repo_config.cloud_env.aws_access_key_id = form.aws_access_key_id
                repo_config.cloud_env.aws_secret_access_key = form.aws_secret_access_key
                break;
            }
            break;
        }
        break;

      case 'gcp':
        repo_config.cloud_option = 'gcp'
        repo_config.sops_encryption = form.gcp_key
        repo_config.cloud_env.gcp_credentials = form.gcp_credentials
        break;

      case 'azure':
        repo_config.cloud_option = 'azure'
        repo_config.sops_encryption = sopsEncryption
        repo_config.cloud_env.azure_tenant_id = azureTenantId
        repo_config.cloud_env.azure_client_id = azureClientId
        repo_config.cloud_env.azure_client_secret = azureClientSecret
        break;
    }

    return payload
  }

  const getUpdatePayload = function (form) {
    const payload = {}

    if (form.git_token) {
      payload.git_token = form.git_token
    }

    if (updateSecrets || awsRoleCreated) {
      payload.cloud_env = {}
      switch (cloudProvider) {
        case 'aws':
          switch (awsKeyProvider) {
            case 'aws_customer_key':
              switch (awsCredentialsType) {
                case 'aws_creds':
                  payload.cloud_option = 'aws_creds'
                  if (form.git_token && ! updateSecrets) {
                    payload.cloud_env = null
                  } else {
                    payload.cloud_env.aws_access_key_id = form.aws_access_key_id
                    payload.cloud_env.aws_secret_access_key = form.aws_secret_access_key  
                  }
                  break;
                case 'aws_role':
                  payload.cloud_option = 'aws_role'
                  payload.cloud_env.aws_role_arn = form.aws_role_arn
                  break;
              }
              break;
            case 'aws_cto_key':
              payload.cloud_env = null
              break;
          }
          break;

        case 'gcp':
          payload.cloud_option = 'gcp'
          if (form.git_token && ! updateSecrets) {
            payload.cloud_env = null
          } else {
            payload.cloud_env.gcp_credentials = form.gcp_credentials
          }
          break;

        case 'azure':
          payload.cloud_option = 'azure'
          payload.cloud_env.azure_tenant_id = form.azure_tenant_id
          payload.cloud_env.azure_client_id = form.azure_client_id
          if (!updateSecrets) {
            payload.cloud_env = null
          } else {
            payload.cloud_env.azure_client_secret = form.azure_client_secret
          }
          break;
      }
    }

    return payload
  }


  const createEcsUser = async (form) => {
    const payload = getCreatePayload(form)

    setFormSubmitting(true)
    setLoading(true)
    const accessToken = 'Bearer ' + localStorage.getItem('accessToken')
    await axios.post(
      process.env.REACT_APP_CTO_API + '/ecs/user',
      payload,
      { headers: { Authorization: accessToken } }
    )
      .then((res) => {
        if (cloudProvider === 'aws' && awsCredentialsType === 'aws_role') {
          setFormSubmitting(false)
          setFormError(null)
          if (res.data) {
            getSettings()
            setUpdateGitToken(false)
            setUpdateSecrets(false)
          } else {
            setFormError('There has been an error, please contact support@cloudtechnologyoffice.com')
            setFormSubmitStatus(false)
          }
        } else {
          navigate('/products/ecs/settings')
        }
      })
      .catch((error) => {
        checkApiError(error, navigate, setFormError)
        setFormSubmitting(false)
        setFormSubmitStatus(false)
        setFormError('An unknown error occured. Please contact support@cloudtechnologyoffice.com')
        setLoading(false)
      })
  }

  const updateEcsUser = async (form) => {

    setFormSubmitting(true)
    setLoading(true)

    const payload = {}
    if (repoName === 'new') {
      payload.add = getCreatePayload(form)
    } else {
      payload.update = {}
      payload.update[repoFormName] = getUpdatePayload(form)
    }

    const accessToken = 'Bearer ' + localStorage.getItem('accessToken')
    await axios.patch(
      process.env.REACT_APP_CTO_API + '/ecs/user',
      payload,
      { headers: { Authorization: accessToken } }
    )
      .then((res) => {
        setFormSubmitting(false)
        setFormError(null)
        if (res.status === 204) {
          if (cloudProvider === 'aws' && awsCredentialsType === 'aws_role' && form.aws_role_arn === undefined) {
            window.location.href = '/products/ecs/settings/' + repoFormName
          } else {
            navigate('/products/ecs/settings') // arn:aws:iam::328933318005:role/ecs-kms-access-role
            // getSettings() arn:aws:kms:eu-west-1:328933318005:key/82432935-4792-47fb-bb6d-5358634d42ac
            // setUpdateGitToken(false)
            // setUpdateSecrets(false)
          }
        } else {
          if (res.status === 400) {
            setFormError(res.data.detail)
          } else {
            setFormError('There has been an error, please contact support@cloudtechnologyoffice.com')
          }
          setFormSubmitStatus(false)
        }
      })
      .catch((error) => {
        console.log(3434)
        checkApiError(error, navigate, setFormError)
        setFormSubmitting(false)
        setFormSubmitStatus(false)
        // setFormError('An unknown error occured. Please contact support@cloudtechnologyoffice.com')
        setLoading(false)
      })
  }

  const onSubmit = (data) => {
    if (!settings?.token) {
      createEcsUser(data)
    } else {
      updateEcsUser(data)
    }
  };

  const validateGcpCredentialsFile = function (e) {
    try {
      JSON.parse(e)
      return true
    } catch (error) {
      return false
    }
  }

  const validateRepoName = function (e) {
    if (e.replace(/^[a-z0-9-]+$/g, '') == '') return true
    return false
  }

  const validateKmsArn = function (e) {
    if (e.replace(/arn:aws:kms:\S+:\d+:\w+\/\S+/g, '') == '') return true
    return false
  }

  const validateRoleArn = function (e) {
    if (e.replace(/arn:aws:iam::\d+:\w+\/\S+/g, '') == '') return true
    return false
  }

  const handleCancel = function (e) {
    navigate('/products/ecs/settings')
  }

  const doDeleteEcsRepo = async () => {
    const accessToken = 'Bearer ' + localStorage.getItem('accessToken')

    const payload = {
      delete: [repoName]
    }

    await axios.patch(
      process.env.REACT_APP_CTO_API + '/ecs/user',
      payload,
      { headers: { Authorization: accessToken } }
    )
      .then((res) => {
        setFormSubmitting(false)
        navigate('/products/ecs/settings')
      })
      .catch((error) => {
        checkApiError(error, navigate, setFormError)
        setFormSubmitting(false)
        setFormError('An unknown error occured. Please contact support@cloudtechnologyoffice.com')
      })
  }

  const deleteEcsRepo = function () {
    if (deleteConfirmed === null) setDeleteConfirmed(false)
    if (deleteConfirmed === true) doDeleteEcsRepo()
  }
  const handleDeleteConfirmed = function (value) {
    if (value === 'DELETE REPO CONNECTION') setDeleteConfirmed(true)
  }

  const label = { inputProps: { 'aria-label': 'Update Secrets' } };

  const userId = localStorage.getItem('userId')

  return (
    <Container maxWidth="md">
      {!loading && <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <Breadcrumbs
          aria-label="breadcrumb"
          sx={{ marginTop: '20px' }}>
          <Link underline="hover" color="inherit" href="/products">
            Products
          </Link>
          <Link underline="hover" color="inherit" href="/products/ecs">
            ECS
          </Link>
          <Link underline="hover" color="inherit" href="/products/ecs/settings">
            Repositories
          </Link>
          <Typography color="text.primary"> {repoName}</Typography>
        </Breadcrumbs>

        {formError &&
          <Typography variant="body1" gutterBottom
            sx={{ mt: 6, mb: 6, color: 'red' }}
          >
            {formError}
          </Typography>
        }


        {formSubmitStatus === false &&
          <Box>
            <form
              autoComplete="off"
              onSubmit={handleSubmit(onSubmit)}
              noValidate
              className="form">

              <Typography variant="body1" gutterBottom
                sx={{ m: 3, fontWeight: 'bold' }}
              >
                Fill in your Git repository details below:
              </Typography>

              <FormControl onChange={(event) => setFormRepoName(event.target.value)} variant="standard" sx={{ m: 1, marginTop: '20px', width: '100%' }}>
                <TextField
                  value={repoFormName ? repoFormName : ''}
                  disabled={repoSettings ? true : false}
                  error={errors.git_url !== undefined}
                  label="Arbitrary Repository Name"
                  id="repo_name"

                  helperText={errors.repo_name ? "Give repository a name, between 3 and 100 characters, alphanumeric and hyphens only" : ""}
                  {...register("repo_name", { disabled: repoSettings ? true : false, required: true, minLength: 3, maxLength: 100, validate: validateRepoName })}
                />
              </FormControl>
              <FormControl variant="standard" sx={{ m: 1, marginTop: '20px', width: '100%' }}>
                <InputLabel id="git_provider">Select Git Provider</InputLabel>
                <Select
                  {...register("git_provider", { required: true })}
                  // helperText={errors.cloud_provider ? "Product option is required" : ""}
                  labelId="git_provider"
                  id="git_provider"
                  disabled={repoSettings ? true : false}
                  value={gitProvider}
                  onChange={(event) => setGitProvider(event.target.value)}
                  label="Select Product Option">

                  <MenuItem key='github' value='github'>Github</MenuItem>
                  <MenuItem key='gitlab' value='gitlab'>Gitlab</MenuItem>
                  <MenuItem key='bitbucket' value='bitbucket'>Bitbucket</MenuItem>
                  <MenuItem key='azure' value='azure'>Azure</MenuItem>
                </Select>
              </FormControl>
              <FormControl onChange={(event) => setGitUrl(event.target.value)} variant="standard" sx={{ m: 1, marginTop: '20px', width: '100%' }}>
                <TextField
                  value={gitUrl ? gitUrl : ''}
                  disabled={repoSettings ? true : false}
                  error={errors.git_url !== undefined}
                  label="Git Repository URL"
                  id="git_url"
                  helperText={errors.git_url ? "Valid Git repository URL is required" : ""}
                  {...register("git_url", { disabled: repoSettings ? true : false, required: true, minLength: 10, maxLength: 500 })}
                />
              </FormControl>

              {repoSettings &&
                <FormControl variant="standard" sx={{ textAlign: "right", m: 1, marginTop: '20px', width: '100%' }}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={updateGitToken}
                        onChange={(event) => setUpdateGitToken(event.target.checked)}
                        id="update_git_token"
                        {...label} />
                    }
                    label="Update Git Token"
                  />
                </FormControl>
              }


              {updateGitToken &&
                <FormControl variant="standard" sx={{ m: 1, marginTop: '20px', width: '100%' }}>
                  <TextField
                    type="password"
                    error={errors.git_token !== undefined}
                    label="Git Token"
                    id="git_token"

                    helperText={errors.git_token ? "Valid Git token is required" : ""}
                    {...register("git_token", { required: true, minLength: 10, maxLength: 500 })}
                  />
                </FormControl>
              }

              <Typography variant="body1" gutterBottom
                sx={{ m: 3, fontWeight: 'bold' }}
              >
                Enter encryption key and key authorization details below:
              </Typography>

              <FormControl variant="standard" sx={{ m: 1, marginTop: '20px', width: '100%' }}>
                <InputLabel id="cloud_provider">Select Cloud Provider</InputLabel>
                <Select
                  {...register("cloud_provider", { required: true })}
                  labelId="cloud_provider"
                  id="cloud_provider"
                  value={cloudProvider}
                  disabled={repoSettings ? true : false}
                  onChange={(event) => setCloudProvider(event.target.value)}
                  label="Select Product Option">

                  <MenuItem key='aws' value='aws'>AWS</MenuItem>
                  <MenuItem key='gcp' value='gcp'>GCP</MenuItem>
                  <MenuItem key='azure' value='azure'>Azure</MenuItem>

                </Select>
              </FormControl>

              {cloudProvider === 'aws' && !loading &&
                <Box>
                  <FormControl variant="standard" sx={{ m: 1, marginTop: '20px', width: '100%' }}>
                    <InputLabel id="aws_key_location">Select Key Location</InputLabel>
                    <Select
                      {...register("aws_key_location", { required: true })}
                      labelId="aws_key_location"
                      id="aws_key_location"
                      value={awsKeyProvider}
                      disabled={repoSettings && updateSecrets === false ? true : false}
                      onChange={(event) => setAwsKeyProvider(event.target.value)}
                      label="Select Key Location">

                      <MenuItem key='aws_customer_key' value='aws_customer_key'>Your Own Key</MenuItem>
                      <MenuItem key='aws_cto_key' value='aws_cto_key'>CTO Provided Key</MenuItem>

                    </Select>
                  </FormControl>
                  {awsKeyProvider === 'aws_cto_key' &&
                    <Box align="center" sx={{ textAlign: 'center', width: '100%' }}>
                      <Stack sx={{ width: '102%' }} spacing={2}>
                        <Alert severity="warning">
                          Important: If you envisage that you might switch to using ECS on-premise, DON'T USE THIS OPTION if you want to carry on using the same configuration repository.
                          <p>Choose "Your Own Key" option and follow instructions to create a KMS key in your own account. Using your own KMS key will work with on-premise or cloud.</p>
                        </Alert>
                      </Stack>
                    </Box>
                  }
                  {awsKeyProvider == 'aws_customer_key' &&
                    <Box>
                      <FormControl onChange={(event) => setSopsEncryption(event.target.value)} variant="standard" sx={{ m: 1, marginTop: '20px', width: '100%' }}>
                        <TextField
                          value={sopsEncryption ? sopsEncryption?.kms_arn : ''}
                          disabled={repoSettings ? true : false}
                          error={errors.aws_key_arn !== undefined}
                          label="Key ARN"
                          id="aws_key_arn"

                          helperText={errors.aws_key_arn ? "Valid key ARN is required" : ""}
                          {...register("aws_key_arn", { disabled: repoSettings ? true : false, required: true, validate: validateKmsArn })}
                        />
                      </FormControl>
                      <FormControl variant="standard" sx={{ m: 1, marginTop: '20px', width: '100%' }}>
                        <InputLabel id="aws_creds">Select Authentication Type</InputLabel>
                        <Select
                          {...register("aws_creds", { required: true })}
                          labelId="aws_creds"
                          id="aws_creds"
                          value={awsCredentialsType ? awsCredentialsType : ''}
                          onChange={(event) => setAwsCredentialsType(event.target.value)}
                          label="Select Credentials Type">

                          <MenuItem key='aws_role' value='aws_role'>Role</MenuItem>
                          <MenuItem key='aws_creds' value='aws_creds'>Credentials</MenuItem>

                        </Select>
                      </FormControl>
                      {awsCredentialsType == 'aws_role' && repoSettings === undefined &&
                        <Stack sx={{ width: '100%' }} spacing={2}>
                          <Alert severity="info">When you save your settings you will be prompted to add a role ARN that can access your KMS key.</Alert>
                        </Stack>
                      }
                      {awsCredentialsType == 'aws_role' && repoSettings &&
                        <Box>
                          <FormControl onChange={(event) => setAwsRoleArn(event.target.value)} variant="standard" sx={{ m: 1, marginTop: '20px', width: '100%' }}>
                            <TextField
                              value={awsRoleArn === 'placeholder' ? '' : awsRoleArn ? awsRoleArn : ''}
                              disabled={(repoSettings && updateSecrets === false) && awsRoleCreated === false ? true : false}
                              error={errors.aws_role_arn !== undefined}
                              label="Role ARN"
                              id="aws_role_arn"

                              helperText={errors.aws_role_arn ? "Valid IAM role ARN is required" : ""}
                              {...register("aws_role_arn", { required: true, validate: validateRoleArn })}
                            />
                          </FormControl>
                          <Stack sx={{ width: '101%' }} spacing={2}>
                            <Alert severity="info">
                              Please make sure you configure your role to trust your ECS executor role. Note, only your organization uses this role:
                              <p><b>{ecsExecutorRoleArn}</b></p>

                            </Alert>
                          </Stack>
                        </Box>
                      }
                      {awsCredentialsType == 'aws_creds' &&
                        <Box>
                          <FormControl onChange={(event) => setAwsAccessKeyId(event.target.value)} variant="standard" sx={{ m: 1, marginTop: '20px', width: '100%' }}>
                            <TextField
                              value={awsAccessKeyId ? awsAccessKeyId : ''}
                              key={updateSecrets ? 'updateAwsAccessKeyId' : 'aws_access_key_id'}
                              disabled={updateSecrets === false ? true : false}
                              error={errors.aws_access_key_id !== undefined}
                              label="AWS_ACCESS_KEY_ID"
                              id="aws_access_key_id"

                              helperText={errors.aws_access_key_id ? "AWS_ACCESS_KEY_ID is required" : ""}
                              {...register("aws_access_key_id", { required: repoSettings === null || updateSecrets, minLength: 3, maxLength: 50 })}
                            />
                          </FormControl>
                          {updateSecrets &&
                            <FormControl variant="standard" sx={{ m: 1, marginTop: '20px', width: '100%' }}>
                              <TextField
                                type="password"
                                error={errors.aws_secret_access_key !== undefined}
                                label="AWS_SECRET_ACCESS_KEY"
                                id="aws_secret_access_key"

                                helperText={errors.aws_secret_access_key ? "AWS_SECRET_ACCESS_KEY is required" : ""}
                                {...register("aws_secret_access_key", { required: true, minLength: 3, maxLength: 50 })}
                              />
                            </FormControl>
                          }
                        </Box>
                      }
                    </Box>
                  }
                </Box>
              }

              {cloudProvider === 'gcp' && !loading &&
                <Box>
                  <FormControl onChange={(event) => repoSettings ? undefined : setSopsEncryption(event.target.value)} variant="standard" sx={{ m: 1, marginTop: '20px', width: '100%' }}>
                    <TextField
                      value={sopsEncryption ? sopsEncryption?.gcp_kms : ''}
                      key={updateSecrets ? 'updateGcpKey' : 'gcp_key'}
                      disabled={repoSettings ? true : false}
                      error={errors.gcp_key !== undefined}
                      label="Key"

                      helperText={errors.gcp_key ? "Key is required" : ""}
                      {...register("gcp_key", { required: repoSettings === null, minLength: 3, maxLength: 150 })}
                    />
                  </FormControl>
                  {updateSecrets &&
                    <FormControl variant="standard" sx={{ m: 1, marginTop: '20px', width: '100%' }}>
                      <TextField
                        placeholder="Paste Credentials File Contents"
                        error={errors.gcp_credentials !== undefined}
                        label="Credentials"
                        multiline
                        rows={10}
                        id="gcp_credentials"

                        helperText={errors.gcp_credentials ? "Credentials are required and must be valid JSON" : ""}
                        {...register("gcp_credentials", { required: true, validate: validateGcpCredentialsFile })}>
                      </TextField>
                    </FormControl>
                  }
                </Box>
              }

              {cloudProvider === 'azure' && !loading &&
                <Box>
                  <FormControl onChange={(event) => repoSettings ? undefined : setSopsEncryption(event.target.value)} variant="standard" sx={{ m: 1, marginTop: '20px', width: '100%' }}>
                    <TextField
                      value={sopsEncryption ? sopsEncryption?.azure_kv : ''}
                      disabled={repoSettings ? true : false}
                      key={updateSecrets ? 'updateAzureKey' : 'azure_key'}
                      error={errors.azure_key !== undefined}
                      label="Vault Key"

                      helperText={errors.azure_key ? "Key is required" : ""}
                      {...register("azure_key", { required: repoSettings === null, minLength: 3, maxLength: 100 })}
                    />
                  </FormControl>
                  <FormControl onChange={(event) => setAzureTenantId(event.target.value)} variant="standard" sx={{ m: 1, marginTop: '20px', width: '100%' }}>
                    <TextField
                      value={azureTenantId ? azureTenantId : ''}
                      disabled={repoSettings && updateSecrets === false ? true : false}
                      key={updateSecrets ? 'updateTenantId' : 'azure_tenant_id'}
                      error={errors.azure_tenant_id !== undefined}
                      label="Tenant ID"
                      id="azure_tenant_id"

                      helperText={errors.azure_tenant_id ? "Tenant ID is required" : ""}
                      {...register("azure_tenant_id", { required: repoSettings === null || updateSecrets, minLength: 3, maxLength: 50 })}
                    />
                  </FormControl>
                  <FormControl onChange={(event) => setAzureClientId(event.target.value)} variant="standard" sx={{ m: 1, marginTop: '20px', width: '100%' }}>
                    <TextField
                      value={azureClientId ? azureClientId : ''}
                      disabled={repoSettings && updateSecrets === false ? true : false}
                      key={updateSecrets ? 'updateClientId' : 'azure_client_id'}
                      error={errors.azure_client_id !== undefined}
                      label="Client ID"
                      id="azure_client_id"

                      helperText={errors.azure_client_id ? "Client ID is required" : ""}
                      {...register("azure_client_id", { required: repoSettings === null || updateSecrets, minLength: 3, maxLength: 50 })}
                    />
                  </FormControl>
                  {updateSecrets &&
                    <FormControl onChange={(event) => setAzureClientSecret(event.target.value)} variant="standard" sx={{ m: 1, marginTop: '20px', width: '100%' }}>
                      <TextField
                        type="password"
                        error={errors.azure_client_secret !== undefined}
                        label="Client Secret"
                        id="azure_client_secret"

                        helperText={errors.azure_client_secret ? "Client secret is required" : ""}
                        {...register("azure_client_secret", { required: true, minLength: 3, maxLength: 50 })}
                      />
                    </FormControl>
                  }
                </Box>
              }
              {repoSettings && awsKeyProvider !== 'aws_cto_key' &&
                <FormControl variant="standard" sx={{ textAlign: "right", m: 1, marginTop: '20px', width: '100%' }}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={updateSecrets}
                        onChange={(event) => setUpdateSecrets(event.target.checked)}
                        id="update_secrets"
                        {...label} />
                    }
                    label="Update Secrets"
                  />
                </FormControl>
              }

              <CtoButton
                endIcon={<ArrowForwardIcon />}
                variant="contained"
                type="submit"
                // onClick={handleSubmit}
                sx={{ mb: 2, mt: 2, width: '101%' }}
                disabled={formSubmitting || deleteConfirmed !== null}>
                Save
              </CtoButton>

              <CtoButton
                startIcon={<ArrowBackIcon />}
                variant="contained"
                onClick={handleCancel}
                sx={{
                  mb: 2,
                  mt: 2,
                  width: '101%',
                  background: '#eeeeee',
                  color: '#333333',
                  "&:hover": {
                    backgroundColor: '#bbbbbb'
                  }
                }}
                disabled={formSubmitting || deleteConfirmed !== null}>
                Cancel
              </CtoButton>
            </form>

            {repoSettings &&
              <Box>
                <Stack sx={{ width: '101%', mb: 2 }} spacing={2}>
                  <Alert severity="info">
                    After you have used your config repository for the first time you will not be able to change the encrpytion key as doing so will mean you will not be able to login using the CLI, neither will you be able to decrypt secrets.

                    <p>Because of this, we disable fields that shouldn't be changed. If you are certain you want to change your encryption key or key provider, delete these ECS settings and create from scratch.</p>
                  </Alert>
                </Stack>

                {deleteConfirmed !== null &&
                  <Box>
                    <Typography variant="body1" gutterBottom
                      sx={{
                        m: 3,
                        color: 'red'
                      }}
                    >
                      Are you sure you want to delete the repository connection "{repoName}"? You will not be able to use ECS to login to your config repository and your secrets will not be able to be decrypted by ECS. Type DELETE REPO CONNECTION to confirm
                    </Typography>
                    <FormControl onChange={(event) => handleDeleteConfirmed(event.target.value)} variant="standard" sx={{ m: 1, marginTop: '20px', width: '100%' }}>
                      <TextField
                        label="Type DELETE REPO CONNECTION"
                        id="delete_confirmed"

                        placeholder="Type DELETE REPO CONNECTION to confirm"
                        {...register("delete_confirmed", { required: true, minLength: 3, maxLength: 50 })}
                      />
                    </FormControl>
                  </Box>
                }
                <CtoButton
                  endIcon={<DeleteForeverIcon />}
                  variant="outlined"
                  onClick={deleteEcsRepo}
                  sx={{
                    width: '101%',
                    marginBottom: '20px',
                    borderColor: 'red',
                    color: 'red',
                    "&:hover": {
                      backgroundColor: 'red',
                      color: "white"
                    },
                  }}
                  disabled={deleteConfirmed === false || formSubmitting}>
                  Delete ECS Repo Connection
                </CtoButton>
                {deleteConfirmed === false &&
                  <CtoButton
                    variant="outlined"
                    onClick={() => setDeleteConfirmed(null)}
                    sx={{
                      width: '102%',
                      color: '#333333',
                      borderColor: '#cccccc',
                      "&:hover": {
                        backgroundColor: '#cccccc',
                        color: "white"
                      }
                    }}
                  >
                    Cancel
                  </CtoButton>
                }
              </Box>
            }
            {awsKeyProvider === 'aws_cto_key' && formSubmitting &&
              <Typography variant="body1" gutterBottom
                sx={{ m: 3 }}
              >
                Creating your dedicated encryption key and private access role.......
              </Typography>
            }

            <Stack sx={{ mb: 2, width: '101%' }} spacing={2}>
              <Alert severity="info">
                To get started with ECS Cloud, click <a target="_new" href="https://doc.cloudtechnologyoffice.com/ecs/latest/installation/cloud/">here</a>.
              </Alert>
            </Stack>

          </Box>
        }
      </Grid>}

      <LinearProgress color="secondary" sx={{ mt: 6 }} style={{ display: formSubmitting || loading ? 'block' : 'none' }} />
    </Container>
  );
}