import React from 'react';
import { Box, Container } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useParams } from "react-router-dom";
import { ElfsightWidget } from 'react-elfsight-widget';

export function Pricing() {
  let { product_code } = useParams();
  const widgetId = process.env['REACT_APP_PRICING_WIDGETS_' + product_code]

  return (
    <Box
      sx={{
        display: 'flex',
        // justifyContent: 'center',
        // alignItems: 'center',
        // minHeight: '100vh'
      }}>

      <Container maxWidth="md">
        <Grid
            container
            sx={{marginTop: '60px'}}
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            >
            <Grid>
              <ElfsightWidget widgetID={widgetId} />
            </Grid>
        </Grid>
      </Container>
    </Box>
  );
}