import React from 'react';
import { Box, Container } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { Authenticator } from '@aws-amplify/ui-react'
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { fetchAuthSession } from "aws-amplify/auth";
import { Hub } from 'aws-amplify/utils';
import { signOut } from 'aws-amplify/auth';

export function Authenticate() {
  const navigate = useNavigate()
  const [userId, setUserId] = React.useState();

  let { signout } = useParams();

  async function handleSignOut() {
    try {
      localStorage.setItem('accessToken', null)

      await signOut();
      // navigate('/')
      window.location.href='/'
    } catch (error) {
      console.log('error signing out: ', error);
    }
  }
  
  if (signout) handleSignOut()
  
  const getSession = async () => {
    const {
      tokens,
      credentials,
      identityId,
      userSub
    } = await fetchAuthSession();
    if (tokens) {
      const {
        idToken,
        accessToken
      } = tokens;
      const userId = idToken.payload['cognito:username']
      setUserId(userId)
      localStorage.setItem('userId', userId)
      localStorage.setItem('accessToken', accessToken.toString())

      window.location.href='/products'
      //navigate('/products')
      
    } else {
      localStorage.setItem('accessToken', null)
      localStorage.setItem('userId', null)
    }
  }
  const listener = (data) => {
    getSession()
  };
  
  const formFields={
    setupTotp: {
      QR: {
        totpIssuer: 'CTO',
        totpUsername: userId,
      },
      confirmation_code: {
        label: 'Enter your Confirmation Code:',
        placeholder: 'Enter your Confirmation Code:',
        isRequired: false,
      },
    },
    confirmSignIn: {
      confirmation_code: {
        label: 'Enter your Confirmation Code:',
        placeholder: 'Enter your Confirmation Code:',
        isRequired: false,
      },
    }
  }
  Hub.listen('auth', listener);

  return (
    <Box
      sx={{
        display: 'flex',
        // justifyContent: 'center',
        // alignItems: 'center',
        // minHeight: '100vh'
      }}
    >
      <Container maxWidth="md">
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            >
            <Typography
              variant="h6"
              gutterBottom
              sx={{m: 2}}
            >
              CTO Account Administration
          </Typography>
          <Typography
            variant="body1" 
            gutterBottom
            sx={{m: 2}}
            >
              Signup or sign in to manage your CTO account 
          </Typography>

          <Authenticator hideSignUp={true} formFields={formFields}  signUpAttributes={['family_name', 'given_name']}/>
          
        </Grid>
      </Container>
    </Box>
  );
}