export const checkApiError = function(error, navigate, setErrorMessage) {
    if (error.response?.status === 403) {
        let counter = 3
        setInterval(() => {
        let errorMessage = 'Your session has timed out. Returning to sign in page in ' + counter + ' seconds'
        setErrorMessage(errorMessage)
        counter --;
        if (counter === -1) navigate('/logout')
        }, 1000)
    } else {
        if (error.response?.status === 400) {
            setErrorMessage(error.response?.data?.detail)
        } else {
            if (error.response?.status !== 404) {
                setErrorMessage('There was an unexpected error, please contact support@cloudtechnologyoffice.com')
            }
        }
    }
}