import _ from 'lodash';
import * as React from 'react';

import { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { useForm } from "react-hook-form";
import {Select, MenuItem, Button, styled, Typography} from '@mui/material'
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import LinearProgress from '@mui/material/LinearProgress';

import ReCAPTCHA from "react-google-recaptcha"

import { useParams, useNavigate } from "react-router-dom";
import * as sanitizeHtml from 'sanitize-html';

import { CtoButton } from '../common/Buttons'

const discountCodes = {
  'WEBINAR10': ''
}
const defaultOptions = {
  allowedTags: [ 'b', 'i', 'em', 'strong', 'a', 'p' ],
  allowedAttributes: {
    'a': [ 'href' ]
  },
  allowedIframeHostnames: []
};

const sanitize = (dirty, options) => ({
  __html: sanitizeHtml(
    dirty, 
    { ...defaultOptions, ...options }
  )
});

const SanitizeHTML = ({ html, options }) => (
  <div dangerouslySetInnerHTML={sanitize(html, options)} />
);

export const Signup = ({authenticatedUser}) => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  let { productCode } = useParams();
  let { productOption = 'free_trial_cloud'} = useParams();
  const [selectedProductOption, setProductOption] = React.useState(productOption);  
  const [productOptions, setProductOptions] = useState([]);
  const captchaRef = useRef(null)
  const [formSubmitStatus, setFormSubmitStatus] = useState(false);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [formError, setFormError] = useState(null);


  const navigate = useNavigate()


  const AuthenticatedSignup = async () => {
    setFormSubmitting(true)
    
    const payload = {
      product_code: productCode,
      product_option: productOption
    }

    const accessToken = 'Bearer ' + localStorage.getItem('accessToken')
    await axios.post(process.env.REACT_APP_CTO_API + '/products', payload, { headers: { Authorization: accessToken } })
    .then((res) =>  {
      setFormSubmitting(true)
      navigate('/products')
    })
    .catch((error) => {
      setFormSubmitting(true)
      if (error.response?.status === 409) {
        let counter = 5
        setInterval(() => {
          let errorMessage = 'You already have a license for ' + _.upperCase(productCode) + '. To change your plan, contact support@cloudtechnologyoffice.com. <p>Returning to Products page in ' + counter + ' seconds</p>'
          setFormError(errorMessage)
          counter --;
          if (counter === -1) navigate('/products')
        }, 1000)
      } else {
        setFormError('There has been an error. Please contact support@cloudtechnologyoffice.com')
      }
      console.log('error', error)
    })
  }

  

  let heading;
  let signupSuccessMessage;
  let submitButtonText;

  switch(productCode) {
    case 'ecs':
      heading = 'Enter your details and we\'ll set up an account for you. When you sign in you will be able to configure your options and receive your API key.'
      signupSuccessMessage = '<p>Please check your email for your username, password and sign in link.</p><p>When you log in, if you selected ECS Cloud you will be able to configure ECS Cloud, if you selected on-premise, you will be able to retrieve your API key.</p><p>&nbsp;</p><p>If you don\'t receive the email please check your spam folder for an email from support@cloudtechnologyoffice.com</p>'
      submitButtonText = 'Create My Account'
      break;
    case 'esh':
      heading = 'Enter your details and we\'ll set up an account for you. When you sign in you will be able to create your Enterprise Service Hub organization.'
      signupSuccessMessage = '<p>Please check your email for your username, password and sign in link.</p><p>When you log in, if you selected ECS Cloud you will be able to configure ECS Cloud, if you selected on-premise, you will be able to retrieve your API key.</p><p>&nbsp;</p><p>If you don\'t receive the email please check your spam folder for an email from support@cloudtechnologyoffice.com</p>'
      submitButtonText = 'Create My Account'
      break;
    default:
      heading = 'Invalid product, only ecs or esh are valid'
      // navigate('/404')
  }

  const getProductData = async () => {
    setFormSubmitting(true)

    await axios.get(process.env.REACT_APP_CTO_API + '/products/licenses?product_code=' + productCode)
    .then((res) =>  {
      let productOptionValid = res.data.filter((option) => option.id == productOption)
      if ( productOptionValid.length === 0) setProductOption('free_trial_cloud')

      setFormSubmitting(false)
      res.data.sort((a, b) => (a?.price > b?.price) ? 1 : -1)
      let productOptionsTemplate = res.data?.map(v => (
        v.payment_type === 'subscription' ? <MenuItem key={v.id} value={v.id}>{v.price ? v.name + ' (US$' + v.price + ')' : v.name} ({v.description})</MenuItem> : ''
      ));
    
      let productOptionsTemplateOneTime =  res.data?.map(v => (
        v.payment_type === 'one_time' ? <MenuItem key={v.id} value={v.id}>{v.price ? v.name + ' (US$' + v.price + ')' : v.name}</MenuItem> : ''
      ));
      productOptionsTemplate.push(...productOptionsTemplateOneTime)
      setProductOptions(productOptionsTemplate)

    })
    .catch((error) => {
      setFormSubmitting(false)
      console.log('Product data error', error)
    })
  }

  // if (authenticatedUser) {
  //   console.log('signup')
  //   AuthenticatedSignup()
  // }
  useEffect(()=>{
    if (authenticatedUser) {
      AuthenticatedSignup()
    } else {
      getProductData()
    }
  }, [authenticatedUser])


  const handleProductOptionChange = (event) => {
    setProductOption(event.target.value);
  }


  const onSubmit = (data) => {
    SubmitForm(data)
  };

  
  const SubmitForm = async (form) => {
      setFormSubmitting(true)

      const token = captchaRef.current.getValue();
      form.product_code=productCode
      captchaRef.current.reset();

      const payload = {
          form: form,
          token: token
      }
 
      await axios.post(process.env.REACT_APP_CTO_API + '/signup', payload)
      .then((res) =>  {
        setFormSubmitting(false)
          setFormError(null)
          if (res.data?.status) {
            setFormSubmitStatus(true)
            // if (selectedProductOption !== 'free_trial') getPaymentSession()
          } else {
            setFormError('Please verify you are not a robot')
            setFormSubmitStatus(false)
          }
      })
      .catch((error) => {
        console.error('error', error)
        setFormSubmitting(false)
        setFormSubmitStatus(false)
        if (error.response?.status === 409) {
          setFormError('A user with that email already exists. Please sign in to add products to your account.')
        } else {
          setFormError(error.response?.data.detail ? error.response?.data.detail : error.response)
        }
      })
  }


  return (
    <Box id="appContainer">
      { authenticatedUser &&
        <Box>
          <Typography sx={{width: '100%', color: 'red', marginTop: '20px', textAlign: 'center'}} variant="div" component="div">
            <SanitizeHTML html={formError}/>
          </Typography>
          <LinearProgress color="secondary" style={{display: formSubmitting ? 'block' : 'none'}}/>
        </Box>

      }
      
      { ! authenticatedUser &&  <Box className="app">
        <Box sx={{width: '500px'}} className="signup_success">
          {
            formSubmitStatus ? <SanitizeHTML html={signupSuccessMessage}/> : ""
          }
        </Box>

        { formSubmitStatus === false && <form
          autoComplete="off" 
          onSubmit={handleSubmit(onSubmit)} 
          className="form">
          <Typography variant="div" component="div">
            <SanitizeHTML html={heading}/>
          </Typography>

          <TextField
            error = {errors.organization_name !== undefined}
            label="Organization Name"
            sx={{ m: 0, marginTop: '20px', width: '100%' }}
            helperText={errors.organization_name ? "Organization name is required and must be at least 3 characters" : ""}
            {...register("organization_name", { required: true, minLength: 3, maxLength: 50})}
          />        

          <TextField
            error = {errors.firstname !== undefined}
            label="First Name"
            sx={{ m: 0, marginTop: '20px', width: '100%' }}
            helperText={errors.firstname ? "First name is required and must be at least 3 characters" : ""}
            {...register("firstname", { required: true, minLength: 3, maxLength: 50})}
          />        

          <TextField
            error = {errors.lastname !== undefined}
            label="Last Name"
            sx={{m: 0,  marginTop: '20px', width: '100%' }}
            helperText={errors.lastname ? "Last name is required and must be at least 3 characters" : ""}
            {...register("lastname", { required: true, minLength: 3, maxLength: 50})}
          />

          <Box>
            <TextField
              error = {errors.email !== undefined}
              label="Email"
              sx={{ m: 0, marginTop: '20px', width: '100%' }}
              type="email"
              helperText={errors.email ? "Email is required" : ""}
              {...register("email", { required: true, pattern: /^\S+@\S+$/i, maxLength: 80 })}
            />
        </Box>

        <FormControl variant="standard" sx={{ m: 1, minWidth: 120, marginTop: '20px', marginBottom: '30px', width: '100%' }}>
          <InputLabel id="product_option">Select Product Option</InputLabel>
          <Select
            {...register("product_option", { required: true })}
            // helperText={errors.product_option ? "Product option is required" : ""}
            labelId="product_option"
            id="product_option"
            value={selectedProductOption ? selectedProductOption : 'free_trial_cloud'}
            onChange={handleProductOptionChange}
            label="Select Product Option">

            {productOptions}
          </Select>
        </FormControl>

        <Box className="recapture">
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_RECAPTURE_SITE_KEY} 
              ref={captchaRef}
            />
          </Box>

          <CtoButton 
            endIcon={<ArrowForwardIcon/>} 
            variant="contained" 
            type="submit"
            sx={{marginBottom: '20px'}}
            disabled={formSubmitting}>
            {submitButtonText}
          </CtoButton>

          <Typography sx={{color: 'red', marginBottom: '20px'}} variant="div" component="div">
            {formError}            
          </Typography>

          <LinearProgress color="secondary" style={{display: formSubmitting ? 'block' : 'none'}}/>

        </form> }
      </Box>
    }
    </Box>
  )
}