import _ from 'lodash';
import axios from 'axios';
import React from 'react';
import {useEffect} from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import LinearProgress from '@mui/material/LinearProgress';

import getStripe from '../common/getStripe';

import {ProductMenu} from '../common/ProductMenu'

import { useNavigate } from "react-router-dom";
import moment from "moment";

import {checkApiError} from '../common/Utils';

export function Products({authenticatedUser}) {
  const navigate = useNavigate()
  
  // if (!authenticatedUser) {
  //   navigate('/')
  // }

  const [products, setProducts] = React.useState([]);
  const [productLoadError, setProductLoadError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [selectedRow, setSelectedRow] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [availableProducts, setAvailableProducts] = React.useState([]);

  const getProducts = async () => {
    setLoading(true)
    const accessToken = 'Bearer ' + localStorage.getItem('accessToken')
    const apiUrl = process.env.REACT_APP_CTO_API + '/products'

    await axios.get(apiUrl, { headers: { Authorization: accessToken } })
      .then((res) =>  {
        let data = res.data.length ? res.data : [{product_name: 'You don\'t appear to have any CTO products'}]
        const addProduct = []
        if (data.filter((product) => product.product_name === 'ecs').length === 0)  addProduct.push(    {
          product_name: 'Enterprise Configuration Service',
          product_code: 'ecs'
        })
        if (data.filter((product) => product.product_name === 'ess').length === 0)  addProduct.push(    {
          product_name: 'Enterprise Service Hub',
          product_code: 'ess'
        })
        setAvailableProducts(addProduct)

        setProductLoadError(false)
        setProducts(data)
        setLoading(false)
      })
      .catch((error) => {
        checkApiError(error, navigate, setErrorMessage)
        let data = [{product_name: 'There was an error loading your products'}]
        setProductLoadError(true)
        setProducts(data)
        setLoading(false)
          // setConfirmationError(error.response?.data.detail ? error.response.data.detail: unknownErrorMessage)
      })
  }

  useEffect(() => {
    getProducts()
  }, [])


  if (selectedRow) {
    const to = '/pricing/' + selectedRow.id
    window.location.href=to;
    // return <Navigate to={to}/>
  }

  const handleSelectRow = (e) => {
    setSelectedRow(e)  
  }

  const handlePlanChange = (row) => {
    alert(row.plan);
  }

  const handleProductClick = product => {
    if (product.api_key) navigate('/products/' + product.product_name)
  }
  
  const aavailableProducts = [
    // {
    //   product_name: 'Enterprise Service Hub',
    //   product_code: 'ess'
    // },
    {
      product_name: 'Enterprise Configuration Service',
      product_code: 'ecs'
    }
  ]

  const buyLicense = function(product) {
    getPaymentSession(product.product_name, product.product_option)
  }

  async function getPaymentSession(productCode, productOption) {
    const payload = {
      product_code: productCode,
      product_option: productOption
    }
    const accessToken = 'Bearer ' + localStorage.getItem('accessToken')

    await axios.post(process.env.REACT_APP_CTO_API + '/payments/details', payload, { headers: { Authorization: accessToken }})
    .then((res) =>  {
      // setFormSubmitting(false)
        // setFormError(null)
        if (res.data) {
          const sessionId = res.data.session_id
          paymentHander(sessionId)
        } else {
          // setFormError('There has been an error, please contact support@cloudtechnologyoffice.com')
          // setFormSubmitStatus(false)
        }
    })
    .catch((error) => {
      console.log('error', error)
        // setFormSubmitting(false)
        // setFormSubmitStatus(false)
        // setFormError(error.response?.data.detail ? error.response?.data.detail : error.response)
    })
  }

  async function paymentHander(sessionId) {
    const stripe = await getStripe();
    const { error } = await stripe.redirectToCheckout({
      sessionId: sessionId
    });
    console.warn(error.message);
  }

  return (
      <Container maxWidth="md">
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            >
          <Typography variant="h5" gutterBottom
            sx={{m: 3}}
            >
              Your Products
          </Typography>
          <Typography variant="body1" gutterBottom
            sx={{m: 3}}
            >
              View your CTO products below. Soon you'll be able to change your plan and subscribe to products you do not already have. Until then, please email us at support@cloudtechnologyoffice.com for any changes required.
          </Typography>
          <TableContainer component={Paper}>
            <Table 
              selectable="true"
              sx={{ 
                // minWidth: 650,
                "& .MuiTableRow-root:hover": {
                  // backgroundColor: "primary.light",
                  cursor: 'pointer',
                  // color: 'white!important'
                }
              }} 
              size="small" 
              aria-label="Product Table">
              <TableHead>
                <TableRow key="header">
                  <TableCell>Product</TableCell>
                  <TableCell>Plan</TableCell>
                  <TableCell>Expires</TableCell>
                  <TableCell align="right">
                    { 
                      availableProducts.length > 0 && <ProductMenu align="right" sx={{textAlign: 'right'}} products={availableProducts}/>
                    }
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? <TableRow key="loading">
                  <TableCell colSpan={3}>
                    <LinearProgress color="secondary" style={{display: true ? 'block' : 'none'}}/>
                  </TableCell>
                </TableRow> : ''}
                {products.map((product) => (
                  <TableRow 
                    hover
                    key={product.product_name}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    onClick={() => {
                      handleProductClick(product)
                    }}
                  >
                    <TableCell
                      sx={{
                        color: productLoadError ? 'red' : ''
                      }}
                      component="td" scope="row">
                      {product.product_name === 'ecs' ? 'Enterprise Configuration Service' : ''}
                      {product.product_name === 'esh' ? 'Enterprise Service Hub' : ''}
                    </TableCell>
                    <TableCell 
                      component="td" 
                      scope="row">
                        {_.startCase(product?.product_option)}
                    </TableCell>
                    <TableCell>
                        {
                          product.api_key === null ? <Button sx={{padding: 0}} onClick={() => buyLicense(product)}>Buy License</Button> :
                          product?.license_expires ? moment(product?.license_expires).format('MM-DD-YYYY') : ''
                        }
                    </TableCell>
                    <TableCell>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>    
          <Typography variant="p" gutterBottom
            sx={{m: 3, color: 'red'}}
            >
              {errorMessage}
          </Typography>
          
        </Grid>
      </Container>
  );
}