import React from 'react';
import { Box, Typography } from '@mui/material';
import { useParams } from "react-router-dom";

const EssSuccess = () => {
  return (
    <Typography variant="div" component="div">
      <p>Thank you for your payment.</p>
      <p>You now need to verify your email address. You will receive an email from support@cloudtechnologyoffice.com with a link to click.</p>
      <p>When you verify your email you will be taken to a page where you will choose a subdomain for your ESH organization.</p>
      <p>Your ESH Organization will then be automatically created and you will receive an email within 15 minutes with your login details.</p>
      <p>We're very happy to have you along with us, please reach out to us at support@cloudtechnologyoffice.com with any issues or ideas for improvements.</p>
      <p>Team CTO</p>
    </Typography> 

  )
}

const EcsSuccess = () => {
  return (
    <Typography variant="div" component="div">
      <p>Thank you for your payment.</p>
      <p>We have emailed you your organizations API key</p>
      <p>We're very happy to have you along with us, please reach out to us at support@cloudtechnologyoffice.com with any issues or ideas for improvements.</p>
      <p>Team CTO</p>
    </Typography> 

  )
}

const Cancelled = () => {
  return (
    <Typography variant="div" component="div">
      <p>Sorry you didn't complete payment for your CTO product.</p>
      <p>We'd love to have you along with us, please reach out to us at support@cloudtechnologyoffice.com if there is something we can do to help you.</p>
      <p>Team CTO</p>
    </Typography> 

  )
}

export function Payment({authenticatedUser}) {

  let { status } = useParams();
  let { product_code } = useParams();

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        // minHeight: '0vh'
      }}>

      {  status === 'success' && product_code === 'ecs' ? 
        <EcsSuccess/>
        : ''}

      { status === 'success' && product_code === 'ess' ? 
        <EssSuccess/>
      : ''}

      { status === 'cancelled' ? 
        <Cancelled/>
      : ''}

    </Box>
  );
}