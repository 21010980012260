import React from 'react';
import { Box, Typography } from '@mui/material';
import { useParams } from "react-router-dom";

const EssSuccess = () => {
  return (
    <Typography variant="div" component="div">
      <p>You now need to verify your email address. You will receive an email from support@cloudtechnologyoffice.com with a link to click.</p>
      <p>When you verify your email you will be taken to a page where you will choose a subdomain for your ESH organization.</p>
      <p>Your access to ESH will a</p>
    </Typography> 

  )
}
export function Payments({authenticatedUser}) {

  let { status } = useParams();
  let { product_code } = useParams();
  let color
  if (status === 'success') color = 'green'
  if (status === 'cancel') color = 'red'

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        // minHeight: '0vh'
      }}>

      {  product_code === 'ess' && status === 'success' ? 
        <Typography variant="div" component="div">
          <p>Your {product_code.toUpperCase()} signup and payment was successful</p>
        </Typography> 
      : ''}

      { product_code === 'ess' && status === 'success' ? 
        <EssSuccess/>
      : ''}

      <Typography sx={{color:color}}  variant="div" component="div">
            {status}
      </Typography>
    </Box>
  );
}