import * as React from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import AddIcon from '@mui/icons-material/Add';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import { useNavigate } from "react-router-dom";

export  function ProductMenu(availableProducts) {
  const navigate = useNavigate()

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (productCode) => {
    setAnchorEl(null);
  };

  const addProduct = (productCode) => {
    navigate('/pricing/' + productCode)
  }

  let productMenu =  availableProducts.products.map(v => (
    <MenuItem key={v.product_code} onClick={() => addProduct(v.product_code)}>
        <AddIcon/> {v.product_name}
    </MenuItem>
    ));

  return (
    <React.Fragment>
      <Box sx={{ display: 'inline-block', alignItems: 'center', textAlign: 'center' }}>
        <Tooltip title="Add Products to Your Account">
          <Button
            onClick={handleClick}
            size="small"
            sx={{ textAlign: 'right', ml: 0 }}
            aria-controls={open ? 'product-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <Box 
                sx={{
                    display: { xs: 'none', md: 'flex' },
                }}
                >
                Add Product
            </Box>
            <AddIcon 
                sx={{
                    display: { xs: 'flex', md: 'none' },
                }}
                >

            </AddIcon>

          </Button>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="product-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&::before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {productMenu}
        </Menu>
    </React.Fragment>
  );
}