import {Button, styled} from '@mui/material'

export const CtoButton = styled(Button)(({theme})=> ({
    // backgroundColor: theme.palette.primary.main,
    "&:hover": {
        backgroundColor: theme.palette.primary.light,
        color: "white"
    },
    // marginTop: 20,
    width: "100%"
}))
