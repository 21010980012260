import * as Sentry from "@sentry/react";

import { AppRoutes } from './AppRoutes';

import  { Amplify} from 'aws-amplify';

import { awsExports } from "./.aws-exports";

import '@aws-amplify/ui-react/styles.css'

import './App.css'

Amplify.configure({
  Auth: {
    Cognito: {
      region: awsExports.REGION,
      // userPoolWebClientId: awsExports.USER_POOL_CLIENT_ID,
      userPoolId: awsExports.USER_POOL_ID,
      signUpVerificationMethod: "code",
      // allowGuestAccess: true,
      userPoolClientId: awsExports.USER_POOL_CLIENT_ID,
      loginWith: { // Optional
        oauth: {
          domain: awsExports.REACT_APP_COGNITO_DOMAIN,
          scopes: ['openid email phone profile aws.cognito.signin.user.admin '],
          redirectSignIn: ['http://localhost:3000/','https://example.com/'],
          redirectSignOut: ['http://localhost:3000/','https://example.com/'],
          responseType: 'code',
        },
        email: 'true',
        phone: false
      }
    }
  }
})

const currentConfig = Amplify.getConfig();

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});


const App = () => {
  return (
    <AppRoutes/>
  )
}

export default App;
