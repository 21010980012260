import _ from 'lodash';
import axios from 'axios';
import React from 'react';
import {useEffect, useState} from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';

import { useNavigate, Navigate } from "react-router-dom";
import {checkApiError} from '../common/Utils';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import LinearProgress from '@mui/material/LinearProgress';

import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Divider from '@mui/material/Divider';
import Switch from '@mui/material/Switch';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { useForm } from "react-hook-form";

import AddIcon from '@mui/icons-material/Add';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { CtoButton } from '../common/Buttons'

export function ProductEcsRepos({authenticatedUser}) {
  const navigate = useNavigate()
  // if (!authenticatedUser) {
  //   window.location.href='/logout'
  // }
  const {
    register,
    formState: { isDirty, errors }
  } = useForm();

  
  const [error, setError] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [user, setUser] = React.useState(null);
  const [repos, setRepos] = React.useState([]);
  const [showUserToken, setShowUserToken] = useState(false);
  const [formError, setFormError] = useState(null);
  const [deleteConfirmed, setDeleteConfirmed] = useState(null);
  const [deleting, setDeleting] = useState(null);
  const [value, setValue] = React.useState(0);

  const getSettings = async () => {
    setLoading(true)
    const accessToken = 'Bearer ' + localStorage.getItem('accessToken')
    const apiUrl = process.env.REACT_APP_CTO_API + '/ecs/user'

    await axios.get(apiUrl, { headers: { Authorization: accessToken } })
      .then((res) =>  {
        setLoading(false)
        const reposTmp = []
        setUser(res.data)
        Object.keys(res.data.repos_config).forEach((repo) => {
          reposTmp.push({'repo_name': repo, repo_url: res.data.repos_config[repo].git_url, key_provider: res.data.repos_config[repo].cloud_option.split('_')[0]})
        })
        if (reposTmp.length === 0) reposTmp.push({'repo_name': 'No repositories configured'})
        setRepos(reposTmp)
      })
      .catch((error) => {
        checkApiError(error, navigate, setError)
        if (error.response?.status === 404) {
          navigate('/products/ecs/settings/new')
        }
        setLoading(false)
      })
  }
  const doDeleteEcsUser = async () => {
    
    setLoading(true)
    const accessToken = 'Bearer ' + localStorage.getItem('accessToken')
    const apiUrl = process.env.REACT_APP_CTO_API + '/ecs/user'

    await axios.delete(apiUrl, { headers: { Authorization: accessToken } })
      .then((res) =>  {
          setLoading(false)
          navigate('/products/ecs')
      })
      .catch((error) => {
        checkApiError(error, navigate, setFormError)
        setLoading(false)
      })
  }


  const deleteEcsUser = function() {
    if (deleteConfirmed === null) setDeleteConfirmed(false)
    if (deleteConfirmed === true) doDeleteEcsUser()
  }
  const handleDeleteConfirmed = function(value) {
    if (value === 'DELETE ECS CLOUD ORGANIZATION') setDeleteConfirmed(true)
  }

  useEffect(() => {
    getSettings()
  }, [])


  const handleRepoClick = function(repo_name) {
    if (repo_name === 'No repositories configured') return
    return navigate('/products/ecs/settings/' + repo_name)
  }

  const newUserClick = function(repo_name) {
    return navigate('/products/ecs/settings/new')
  }
  const label = { inputProps: { 'aria-label': 'Update Secrets' } };

  const userId = localStorage.getItem('userId')

  function CustomTabPanel(props) {

    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ paddingTop: 3 }}>
            {children}
          </Box>
        )}
      </div>
    );
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  
  return (
      <Container  maxWidth="md">
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Breadcrumbs 
            aria-label="breadcrumb"
            sx={{marginTop: '20px'}}>
            <Link underline="hover" color="inherit" href="/products">
              Products
            </Link>
            <Link underline="hover" color="inherit" href="/products/ecs">
              ECS
            </Link>
            <Typography color="text.primary">Organization Settings</Typography>
          </Breadcrumbs>


          <Typography variant="body1" gutterBottom
              sx={{mt: 6, mb: 6}}
              >
              Your ECS cloud organization settings are shown below. Either manage your configuration repositories or click ECS CLOUD ORGANIZATION to view your ECS Cloud API token. 
            </Typography>

          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="left"
            justifyContent="center"
          >
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={value} onChange={handleChange} aria-label="Tabs">
                <Tab label="Repositories" {...a11yProps(0)} />
                <Tab label="ECS Cloud Organization" {...a11yProps(1)} />
              </Tabs>
            </Box>
          </Grid>
      

          <CustomTabPanel value={value} index={0} >
            <Typography variant="body1" gutterBottom
              sx={{mt: 3, mb: 3}}
              >
              You have the following configuration repositories setup. Click ADD REPO to create another or select a repo to see details or update secrets.
            </Typography>

            { error && <Typography variant="body1" gutterBottom
              sx={{mt: 3, mb: 3, color: 'red'}}
              >
              {error}
            </Typography>}

            <TableContainer component={Paper}>
              <Table 
                selectable="true"
                sx={{ 
                  // minWidth: 650,
                  "& .MuiTableRow-root:hover": {
                    // backgroundColor: "primary.light",
                    cursor: 'pointer',
                    // color: 'white!important'
                  }
                }} 
                size="small" 
                aria-label="Product Table">
                <TableHead>
                  <TableRow key="header">
                    <TableCell>Repo Name</TableCell>
                    <TableCell>Repo URL</TableCell>
                    <TableCell>Key Provider</TableCell>
                    <TableCell align="right">
                    <CtoButton 
                      endIcon={<AddIcon/>} 
                      // variant="outlined" 
                      onClick={newUserClick}
                      sx={{
                        width: '150px',
                        "&:hover": {
                          backgroundColor: '#eeeeee',
                        },
                      }}
                      disabled={deleteConfirmed === false || deleting}>
                      Add Repo
                    </CtoButton>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  { loading && <TableRow key="loading">
                    <TableCell colSpan={4}>
                      <LinearProgress color="secondary" style={{display: true ? 'block' : 'none'}}/>
                    </TableCell>
                  </TableRow> 
                  }
                  
                  { !loading && repos.map((repo) => ( 
                    <TableRow 
                    key={repo.repo_name}
                    onClick={() => {
                      handleRepoClick(repo.repo_name)
                    }}>
                      <TableCell>{repo.repo_name}</TableCell>
                      <TableCell>{repo.repo_url}</TableCell>
                      <TableCell>{repo.key_provider === 'azure' ? 'Azure' : _.upperCase(repo.key_provider)}</TableCell>
                      <TableCell></TableCell>
                    </TableRow> )
                  )}
                </TableBody>
              </Table>
            </TableContainer> 
          </CustomTabPanel>

          <CustomTabPanel value={value} index={1}>
            <Typography variant="body1" gutterBottom
              sx={{mt: 3, mb: 3}}
              >
             Click Show ECS Cloud Token to obtain authentication details for CLI.
            </Typography>

            { error && <Typography variant="body1" gutterBottom
              sx={{mt: 3, mb: 3, color: 'red'}}
              >
              {error}
            </Typography>}

            <FormControl  variant="standard" sx={{ textAlign: "left", m: 1, marginTop: '20px', width: '100%' }}>
                { user && 
                <FormControlLabel
                  control={
                  <Switch 
                    checked={showUserToken} 
                    onChange={(event) => setShowUserToken(event.target.checked)} 
                    id="show_user_token" 
                    {...label}  />
                  }
                  label="Show ECS Cloud Token"
                />
                }
                { showUserToken && 
                <Box>
                  <Typography variant="body1" gutterBottom
                    sx={{mt: 3, mb: 3}}
                    >
                    Use this token to authenticate your CTO CLI client with the ECS Cloud API:
                  </Typography>
                  <Typography variant="body1" gutterBottom
                    sx={{mt: 3, mb: 3, fontWeight: 'bold'}}
                    >
                    {user?.token}
                  </Typography>
                  <Divider 
                    sx={{ mb: 3}}>
                  </Divider>
                </Box>
                }
              </FormControl>


              { deleteConfirmed !== null &&
              <Box>
                <Typography variant="body1" gutterBottom
                  sx={{
                    m: 3,
                    color: 'red'
                  }}
                  >
                  Are you sure you want to delete your ECS cloud user? You will not be able to use ECS to login to your config repositories and your secrets will not be able to be decrypted by ECS. Type DELETE ECS CLOUD ORGANIZATION to confirm
                </Typography>
                <FormControl onChange={(event) => handleDeleteConfirmed(event.target.value)} variant="standard" sx={{ m: 1, marginTop: '20px', width: '100%' }}>
                  <TextField
                    label="Type DELETE ECS CLOUD ORGANIZATION"
                    id="delete_confirmed"
                    
                    placeholder="Type DELETE ECS CLOUD ORGANIZATION to confirm"
                    {...register("delete_confirmed", { required: true, minLength: 3, maxLength: 50})}
                  /> 
                </FormControl>
              </Box>
            }
            <Grid
              container
              spacing={0}
              direction="column"
              alignItems="left"
              justifyContent="center"
            >

              <Typography variant="body1" gutterBottom
                sx={{mt: 6, mb: 6}}
                >
                If you no longer wish to use ECS Cloud, delete your organization below:
              </Typography>
              <CtoButton 
                endIcon={<DeleteForeverIcon/>} 
                variant="outlined" 
                onClick={deleteEcsUser}
                sx={{
                  width: '100%',
                  marginBottom: '20px', 
                  borderColor: 'red', 
                  color: 'red',
                  "&:hover": {
                    backgroundColor: 'red',
                    color: "white"
                  },
                }}
                disabled={deleteConfirmed === false || deleting}>
                Delete ECS Cloud Organization
              </CtoButton>
            </Grid>
            { deleteConfirmed === false &&
                <CtoButton 
                  variant="outlined"
                  onClick={() => setDeleteConfirmed(null)}
                  sx={{
                    width: '102%',
                    color: '#333333',
                    borderColor: '#cccccc',
                    "&:hover": {
                      backgroundColor: '#cccccc',
                      color: "white"
                    }
                  }}
                >
                Cancel
              </CtoButton>
            }
          </CustomTabPanel>

        </Grid>



        {/* </Grid> */}

            
      </Container>
  );
}